import React, { useMemo } from 'react';
import {
  DidomiButton,
  DidomiIcon,
  DidomiSelectField,
  DidomiSelectOption,
  DidomiSelectOptions,
  DidomiTextInputField,
} from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';
import { Form, Formik } from 'formik';
import { Role, UserFormData } from '@types';
import {
  NO_ROLE_FIELD_VALUE,
  normalizeUserFormValuesForForm,
  normalizeUserFormValuesForSubmit,
} from '@utils';
import { DEFAULT_INITIAL_FORM_STATE, getUserSchema } from './UserFormHelpers';

interface UserFormProps {
  initialFormState?: UserFormData | null;
  alreadyTakenEmails?: string[];
  roles: Role[];
  onSave: (values: UserFormData) => void;
  onCancel: () => void;
}

export const UserForm = ({
  initialFormState,
  alreadyTakenEmails,
  roles,
  onSave,
  onCancel,
}: UserFormProps) => {
  const isEditState = !!initialFormState;
  const submitBtnText = isEditState ? 'Apply Changes' : 'Send Invitation';

  const validationSchema = useMemo(() => getUserSchema(alreadyTakenEmails), [alreadyTakenEmails]);

  const initialFormValues = useMemo(() => {
    return normalizeUserFormValuesForForm(initialFormState || DEFAULT_INITIAL_FORM_STATE);
  }, [initialFormState]);

  return (
    <Formik
      initialValues={initialFormValues}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={v => onSave(normalizeUserFormValuesForSubmit(v))}
    >
      <Form className={tx('w-[400px]')} noValidate>
        <DidomiTextInputField
          name="email"
          label="Email Address"
          data-cy="user-email-input"
          disabled={isEditState}
          required
        />
        <DidomiTextInputField
          name="name"
          label="Name"
          data-cy="user-name-input"
          disabled={isEditState}
          required
        />
        <DidomiSelectField
          name="role_id"
          placeholder="Choose a role"
          label="Role"
          data-cy="user-role-select"
          required
        >
          <DidomiSelectOptions>
            <DidomiSelectOption label="No role" value={NO_ROLE_FIELD_VALUE}></DidomiSelectOption>
            {roles?.map(role => (
              <DidomiSelectOption
                key={role.id}
                label={role.name}
                value={role.id}
              ></DidomiSelectOption>
            ))}
          </DidomiSelectOptions>
        </DidomiSelectField>

        <div className={tx('mb-m text-left text-xs italic')}>
          Fields with{' '}
          <sup>
            <DidomiIcon className={tx('text-primary-blue-6')} name="required-sm" />
          </sup>{' '}
          are required
        </div>

        <div className={tx('flex gap-xs justify-center')}>
          <DidomiButton variant="secondary" type="reset" onClick={onCancel}>
            Cancel
          </DidomiButton>
          <DidomiButton type="submit">
            <>{submitBtnText}</>
          </DidomiButton>
        </div>
      </Form>
    </Formik>
  );
};
