import { useNavigate } from 'react-router-dom';
import React from 'react';
import { RoleForm } from '@components';
import { useAccessModules, useRolesApi } from '@hooks';
import { LoadingModal } from '@modals';
import { RoleFormData } from '@types';
import { ROUTES } from '@utils';

export const CreateRole = () => {
  const navigate = useNavigate();
  const { createRole, isSaving } = useRolesApi();
  const { accessModulesBlocks } = useAccessModules();

  const handleCreateRole = (roleData: RoleFormData) => {
    createRole(roleData, { onSuccess: () => navigate(ROUTES.ROLES) });
  };

  return (
    <section className="p-l pb-20 text-primary-blue-6">
      <RoleForm
        isSaving={isSaving}
        accessModulesBlocks={accessModulesBlocks}
        onBackClicked={() => navigate(ROUTES.ROLES)}
        onSubmit={handleCreateRole}
      />
      <LoadingModal isOpen={isSaving} title="Saving changes..." />
    </section>
  );
};
