import { ExtendedRole, Member, Role } from '@types';

export const joinRolesWithMembers = (
  roles: Role[] = [],
  members: Member[] = [],
): ExtendedRole[] => {
  const membersPerRoleId = members?.reduce((acc, member) => {
    const membersCount = (acc[member.role_id] || 0) + 1;
    return { ...acc, [member.role_id]: membersCount };
  }, {});

  return roles?.map(role => ({ ...role, members_count: membersPerRoleId[role.id] || 0 }));
};
