import React from 'react';
import { DidomiIcon, DidomiIconButton, DidomiTooltip } from '@didomi/ui-atoms-react';
import { AuthenticationMethod } from '@types';

const AUTH_METHOD_TO_TEXT = {
  saml_sso: 'SAML SSO',
  email_password: 'Email/Password',
};

export const AuthMethodCellContent = ({
  authMethod,
  samlActive,
}: {
  authMethod: AuthenticationMethod;
  samlActive: boolean;
}) => (
  <>
    {AUTH_METHOD_TO_TEXT[authMethod] || authMethod}
    {AUTH_METHOD_TO_TEXT[authMethod] === 'saml_sso' && (
      <DidomiIcon name={samlActive ? 'success-medium' : 'in-progress-medium'} />
    )}
  </>
);

export const UsersTableControlsCell = ({ onDeleteClicked, onEditClicked }) => (
  <div className="flex gap-xxs">
    <DidomiTooltip content="Edit user" placement="top">
      <DidomiIconButton
        aria-label="Edit user"
        type="button"
        variant="rounded"
        icon="edit"
        data-cy="edit-row"
        onClick={() => onEditClicked()}
      />
    </DidomiTooltip>
    <DidomiTooltip content="Delete user" placement="top">
      <DidomiIconButton
        aria-label="Delete user"
        type="button"
        variant="rounded"
        icon="delete"
        data-cy="delete-row"
        onClick={() => onDeleteClicked()}
      />
    </DidomiTooltip>
  </div>
);
