import { RoleFormData, RoleFormValues } from '@types';

/**
 * Transform role form values to role data.
 * E.g.
 * { name: 'test', description: '', access_policy_ids: { cmp: 'cmp_editor', analytics: 'analytics_viewer' } }
 * =>
 * { name: 'test', description: '', access_policy_ids: ['cmp_editor', 'analytics_viewer'] }
 */
export const roleFormValuesToRoleData = (values: RoleFormValues): RoleFormData => {
  const selectedAccessPolicies = Object.values(values.access_policy_ids).filter(Boolean);
  return { ...values, access_policy_ids: selectedAccessPolicies };
};

/**
 * Transform role data to role form values.
 * E.g.
 * { name: 'test', description: '', access_policy_ids: ['cmp_editor', 'analytics_viewer'] }
 * =>
 * { name: 'test', description: '', access_policy_ids: { cmp: 'cmp_editor', analytics: 'analytics_viewer' } }
 */
export const roleDataToRoleFormValues = (roleData: RoleFormData): RoleFormValues => {
  const accessPoliciesRecord = roleData.access_policy_ids.reduce((acc, accessPolicy) => {
    const moduleId = accessPolicy.split('_')[0];
    return { ...acc, [moduleId]: accessPolicy };
  }, {});
  return { ...roleData, access_policy_ids: accessPoliciesRecord };
};
