import React from 'react';
import {
  DidomiButton,
  DidomiIllustration,
  DidomiModal,
  DidomiModalHeader,
  DidomiModalActions,
  DidomiButtonGroup,
} from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';
import { Member } from '@types';

type ConfirmUserDeleteModalProps = {
  isOpen: boolean;
  member: Member | null;
  onCancel?: () => void;
  onConfirm?: () => void;
};

export const ConfirmUserDeleteModal = ({
  isOpen,
  member,
  onCancel,
  onConfirm,
}: ConfirmUserDeleteModalProps) => {
  return (
    <DidomiModal isOpen={isOpen} permanent variant="slim">
      <DidomiIllustration className={tx('mb-m')} name="delete-user" />
      <DidomiModalHeader
        modalSubTitle="This user will be deleted:"
        modalTitle={member?.user.email}
        modalDescription={`ID: ${member?.id}`}
      />

      <DidomiModalActions>
        <DidomiButtonGroup>
          <DidomiButton variant="secondary" onClick={onCancel}>
            Cancel
          </DidomiButton>
          <DidomiButton data-cy="confirm-btn" onClick={onConfirm}>
            Delete user
          </DidomiButton>
        </DidomiButtonGroup>
      </DidomiModalActions>
    </DidomiModal>
  );
};
