import { matchId } from '@didomi/helpers';
import { Role } from '@types';

export const getRoleFormDataByRoleId = (roleId: string, roles: Role[]) => {
  const role = roles?.find(matchId(roleId));

  if (role) {
    const { name, description, access_policy_ids } = role;
    return { name, description, access_policy_ids };
  }
};
