import { NavLink, Outlet } from 'react-router-dom';
import React from 'react';
import {
  DidomiFeatureHeader,
  DidomiNavTabHeaders,
  DidomiNavTabs,
  DidomiSmallPush,
  DidomiNavTabHeading,
  DidomiNavTabHeader,
  DidomiNavTabContent,
  DidomiNavTabContents,
} from '@didomi/ui-atoms-react';
import { ROUTES } from '@utils';

export const HomeLayout = () => {
  return (
    <section className="p-l pb-unsafe-bottom-space text-primary-blue-6">
      <DidomiFeatureHeader titleText="Users & Roles" className="w-full block mb-s">
        <div slot="description">
          Manage users and assign them a role to change their permissions.
        </div>

        <DidomiSmallPush
          href="https://support.didomi.io/understand-roles-management"
          target="_blank"
          slot="actions"
          text="Help Center"
          highlighted-text="Users & Roles"
          smallIcon="right-on"
        ></DidomiSmallPush>
      </DidomiFeatureHeader>

      <DidomiNavTabs>
        <DidomiNavTabHeading>
          <DidomiNavTabHeaders>
            <DidomiNavTabHeader>
              <NavLink
                className={navData => (navData.isActive ? 'didomi-active-tab' : '')}
                to={ROUTES.USERS}
              >
                Users
              </NavLink>
            </DidomiNavTabHeader>
            <DidomiNavTabHeader>
              <NavLink
                className={navData => (navData.isActive ? 'didomi-active-tab' : '')}
                to={ROUTES.ROLES}
              >
                Roles
              </NavLink>
            </DidomiNavTabHeader>
          </DidomiNavTabHeaders>
        </DidomiNavTabHeading>

        <DidomiNavTabContents>
          <DidomiNavTabContent className="pt-m">
            <Outlet />
          </DidomiNavTabContent>
        </DidomiNavTabContents>
      </DidomiNavTabs>
    </section>
  );
};
