import { useSnackbar } from '@didomi/utility-react';
import { useDeleteMember } from './useDeleteMember.hook';
import { useEditMember } from './useEditMember.hook';
import { useInviteMember } from './useInviteMember.hook';
import { useMembers } from './useMembers.hook';
import { useRoles } from './useRoles.hook';
import { useSsoConnections } from './useSsoConnections.hook';

export const useUsersApi = () => {
  const { data: members, isLoading: isMemberLoading } = useMembers();
  const { data: roles, isLoading: isRolesLoading } = useRoles();
  const { data: ssoConnections, isLoading: isSsoConnectionsLoading } = useSsoConnections();
  const { displaySnackbar } = useSnackbar();

  const handleApiError = err => {
    const message = err?.response?.data?.message || `An error occurred while saving the changes`;
    displaySnackbar(message, { variant: 'error', duration: 5000 });
  };

  // Mutations
  const { mutate: deleteMember, isLoading: isDeleting } = useDeleteMember({
    onSuccess: () => displaySnackbar(`User has been successfully deleted`),
    onError: handleApiError,
  });

  const { mutate: inviteMember, isLoading: isInviting } = useInviteMember({
    onSuccess: () => displaySnackbar('Invitation sent'),
    onError: handleApiError,
  });

  const { mutate: editMember, isLoading: isUpdating } = useEditMember({
    onSuccess: () => displaySnackbar('User has been successfully updated'),
    onError: handleApiError,
  });

  const isSaving = isDeleting || isInviting || isUpdating;
  const isLoading = isMemberLoading || isRolesLoading || isSsoConnectionsLoading;

  return {
    members,
    roles,
    isLoading,
    isSaving,
    ssoConnections,
    deleteMember,
    inviteMember,
    editMember,
  };
};
