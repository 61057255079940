import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { Member, MemberCore, UserFormData } from '@types';
import { useAxiosWithLogOut } from './useAxiosWithInterceptors.hook';

export const useEditMember = (
  options?: UseMutationOptions<
    AxiosResponse<MemberCore>,
    AxiosError<{ message: string }>,
    { memberId: string; data: Partial<UserFormData> }
  >,
) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();
  const queryClient = useQueryClient();

  const updateMemberInCache = (updatedMemberId: string, newRoleId: string) => {
    queryClient.setQueryData<Member[]>(['members', organizationId], prevMembers =>
      prevMembers.map(member =>
        member.id === updatedMemberId ? { ...member, role_id: newRoleId } : member,
      ),
    );
  };

  return useMutation(
    async ({ memberId, data }) => {
      return axiosWithInterceptors.patch<MemberCore>(
        `${CONFIG.environmentConfig.apiBaseUrl}members/${memberId}`,
        { role_id: data.role_id },
      );
    },
    {
      ...options,
      onSuccess: (response, memberData, context) => {
        updateMemberInCache(memberData.memberId, memberData.data.role_id);
        options.onSuccess?.(response, memberData, context);
      },
    },
  );
};
