import { PaginatedResponse } from '@didomi/helpers';
import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { Member } from '@types';
import { useAxiosWithLogOut } from './useAxiosWithInterceptors.hook';

/**
 * Fetch all members
 * */
export const useMembers = (options?: UseQueryOptions<Member[]>) => {
  const { organizationId } = useActiveOrganization();
  const axiosWithInterceptors = useAxiosWithLogOut();

  const fetchMembers = async (): Promise<Member[]> => {
    const response = await axiosWithInterceptors.get<PaginatedResponse<Member>>(
      `${CONFIG.environmentConfig.apiBaseUrl}members?organization_id=${organizationId}&$limit=5000`,
    );

    return response.data.data;
  };

  return useQuery<Member[], AxiosError>(['members', organizationId], fetchMembers, options);
};
