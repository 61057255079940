import * as Yup from 'yup';
import { PATTERNS } from '@utils';

export const getDuplicateRoleSchema = (takenNames = []) =>
  Yup.object().shape({
    name: Yup.string()
      .trim()
      .notOneOf(takenNames, 'Role with this name already exists in this organization.')
      .matches(PATTERNS.roleName, 'Please enter a valid name.')
      .required('Please enter a name.'),
  });
