import React, { useCallback, useMemo, useState } from 'react';
import { filterSortPaginate } from '@didomi/helpers';
import { useTableState } from '@didomi/helpers-react';
import { DidomiEmptyState, DidomiHintbox, DidomiPaginator } from '@didomi/ui-atoms-react';
import { useHasAccessPolicies } from '@didomi/utility-react';
import { Loader, UsersTable, UsersTableToolbar } from '@components';
import { useCatchLastPageNoItems, useUsersApi } from '@hooks';
import { ConfirmUserDeleteModal, EditUserModal, InviteUserModal, LoadingModal } from '@modals';
import {
  ACCESS_POLICIES_CONFIG,
  hasActiveSsoConnection,
  joinMembersWithRoles,
  matchRole,
} from '@utils';
import {
  DIALOG_CLOSED_STATE,
  DialogState,
  INITIAL_TABLE_STATE,
  SEARCH_FIELDS,
} from './UsersHelpers';

export const Users = () => {
  const [dialogState, setDialogState] = useState<DialogState>(DIALOG_CLOSED_STATE);
  const { hasAccess: hasEditAccess } = useHasAccessPolicies(ACCESS_POLICIES_CONFIG.EDITOR);
  const {
    members,
    roles,
    ssoConnections,
    isLoading,
    isSaving,
    editMember,
    inviteMember,
    deleteMember,
  } = useUsersApi();

  const tableState = useTableState(INITIAL_TABLE_STATE);

  const membersData = useMemo(() => joinMembersWithRoles(members, roles), [members, roles]);
  const membersEmails = useMemo(() => members?.map(member => member.user.email), [members]);
  const isSsoConnectionEnabled = useMemo(
    () => hasActiveSsoConnection(ssoConnections),
    [ssoConnections],
  );

  const filterByRole = useCallback(matchRole(tableState.filters.role as string), [
    tableState.filters.role,
  ]);

  const { data: membersToDisplay, total: paginationTotal } = useMemo(
    () =>
      filterSortPaginate(membersData, {
        search: tableState.search,
        sortConfig: tableState.sortConfig,
        limit: tableState.limit,
        page: tableState.page,
        searchFields: SEARCH_FIELDS,
        filters: [filterByRole],
      }),
    [tableState, membersData, filterByRole],
  );
  useCatchLastPageNoItems({
    page: tableState.page,
    data: membersToDisplay,
    effect: page => tableState.changePage(new CustomEvent('', { detail: { page } })),
  });

  const isEmpty = membersToDisplay.length === 0;

  return (
    <section className="w-full">
      {isSsoConnectionEnabled && (
        <DidomiHintbox
          className="mb-s"
          icon-name="warning"
          title-text="Your administrator has enabled Single Sign-On for your entire organization."
          variant="warning"
        ></DidomiHintbox>
      )}

      <UsersTableToolbar
        numberOfResults={paginationTotal}
        isCreateVisible={hasEditAccess}
        availableRoles={roles}
        onSearchChange={tableState.changeSearch}
        onFilterChange={role => tableState.changeFilters({ role })}
        onCreateClicked={() => setDialogState({ type: 'invite', context: null })}
      />

      {!isEmpty && (
        <>
          <UsersTable
            className="mb-xs"
            members={membersToDisplay}
            sortConfig={tableState.sortConfig}
            search={tableState.search}
            hasEditAccess={hasEditAccess}
            onDeleteClicked={member => setDialogState({ type: 'delete', context: member })}
            onEditClicked={member => setDialogState({ type: 'edit', context: member })}
            onSortChange={tableState.changeSorting}
          />

          <div className="flex justify-end">
            <DidomiPaginator
              data-testid="users-paginator"
              className="self-end"
              page={tableState.page}
              itemCount={paginationTotal}
              size={tableState.limit as any}
              onPageSizeChange={tableState.changeLimit}
              onPageChange={tableState.changePage}
            />
          </div>
        </>
      )}

      {/* Empty State */}
      {isEmpty && !isLoading && (
        <DidomiEmptyState
          illustration-name="traces-no-result-found"
          actionName={hasEditAccess ? 'Add a new user' : ''}
          actionIconRight={hasEditAccess ? 'new-create' : ''}
          onActionClick={() => setDialogState({ type: 'invite', context: null })}
        >
          <div slot="title">It&apos;s empty here!</div>
          No results
        </DidomiEmptyState>
      )}

      {/* Loading State */}
      {isLoading && <Loader>Loading users</Loader>}

      {/* Modals */}
      <LoadingModal isOpen={isSaving} title="Saving changes..." />

      <ConfirmUserDeleteModal
        isOpen={dialogState.type == 'delete'}
        member={dialogState.context}
        onConfirm={() => {
          deleteMember(dialogState.context.id);
          setDialogState(DIALOG_CLOSED_STATE);
        }}
        onCancel={() => setDialogState(DIALOG_CLOSED_STATE)}
      />
      <InviteUserModal
        isOpen={dialogState.type === 'invite'}
        alreadyTakenEmails={membersEmails}
        roles={roles}
        onSave={data => {
          inviteMember(data);
          setDialogState(DIALOG_CLOSED_STATE);
        }}
        onCancel={() => setDialogState(DIALOG_CLOSED_STATE)}
      />
      <EditUserModal
        isOpen={dialogState.type === 'edit'}
        member={dialogState.context}
        roles={roles}
        onSave={data => {
          editMember({ memberId: dialogState.context.id, data });
          setDialogState(DIALOG_CLOSED_STATE);
        }}
        onCancel={() => setDialogState(DIALOG_CLOSED_STATE)}
      />
    </section>
  );
};
