import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { Role, RoleFormData } from '@types';
import { useAxiosWithLogOut } from './useAxiosWithInterceptors.hook';

export const useCreateRole = (
  options?: UseMutationOptions<AxiosResponse<Role>, AxiosError<{ message: string }>, RoleFormData>,
) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();
  const queryClient = useQueryClient();

  const updateRolesInCache = (newRole: Role) => {
    const cachedRoles = queryClient.getQueryData<Role[]>(['roles', organizationId]);

    // Update cache only if roles are already fetched
    if (cachedRoles) {
      queryClient.setQueryData<Role[]>(['roles', organizationId], prevRoles => [
        ...prevRoles,
        newRole,
      ]);
    }
  };

  return useMutation(
    async (roleData: RoleFormData) => {
      return axiosWithInterceptors.post<Role>(
        `${CONFIG.environmentConfig.apiBaseUrl}roles?organization_id=${organizationId}`,
        roleData,
      );
    },
    {
      ...options,
      onSuccess: (response, rolesData, context) => {
        updateRolesInCache(response.data);
        options.onSuccess?.(response, rolesData, context);
      },
    },
  );
};
