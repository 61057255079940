import React from 'react';
import { getCounterText } from '@didomi/helpers';
import {
  DidomiButton,
  DidomiIllustration,
  DidomiModal,
  DidomiModalHeader,
  DidomiModalActions,
} from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';
import { ExtendedRole } from '@types';

type RoleDeleteForbiddenModalProps = {
  isOpen: boolean;
  role: ExtendedRole | null;
  onCancel?: () => void;
};

export const RoleDeleteForbiddenModal = ({
  isOpen,
  role,
  onCancel,
}: RoleDeleteForbiddenModalProps) => {
  return (
    <DidomiModal isOpen={isOpen} permanent>
      <DidomiIllustration className={tx('mb-m')} name="content-loading-error" />
      <DidomiModalHeader
        variant="warning"
        modalSubTitle="Sorry, you can’t delete this role"
        modalTitle={`You can only delete roles that are not assigned to any users. This role is assigned to ${getCounterText(
          role?.members_count,
          'user',
        )}.`}
      />

      <DidomiModalActions>
        <DidomiButton onClick={onCancel}>Close</DidomiButton>
      </DidomiModalActions>
    </DidomiModal>
  );
};
