import React from 'react';
import { DidomiTooltip } from '@didomi/ui-atoms-react';
import Highlighter from 'react-highlight-words';

type TextWithHighlighterAndTooltipProps = {
  text: string;
  searchText: string;
};

export const TextWithHighlighterAndTooltip = ({
  text,
  searchText,
}: TextWithHighlighterAndTooltipProps) => {
  const safeSearchText = searchText.replace(/([.?*+^$[\]\\(){}|-])/g, '');

  return (
    <DidomiTooltip content={text} onlyIfTruncated>
      <div className="truncate !leading-[16px]">
        <Highlighter
          highlightClassName="text-primary-pink-4 bg-transparent"
          searchWords={[safeSearchText]}
          textToHighlight={text}
          autoEscape
        />
      </div>
    </DidomiTooltip>
  );
};
