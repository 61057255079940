export const CHIP_CONFIG_PER_USER_STATUS = {
  active: {
    type: 'success',
    icon: 'check-sm',
    label: 'Activated',
  },
  pending: {
    type: 'info',
    icon: 'reset-sm',
    label: 'Pending',
  },
} as const;
