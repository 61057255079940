import { Member } from '@types';

export const INITIAL_TABLE_STATE = {
  defaultSorting: { field: 'user.name', dir: 'asc' },
  defaultLimit: 10,
  defaultFilters: { role: '' },
} as const;

export const SEARCH_FIELDS = [
  { field: 'id' },
  { field: 'user.email' },
  { field: 'user.name' },
  { field: 'role_name' },
];

export type DialogState = {
  type: 'delete' | 'edit' | 'invite' | null;
  context: Member | null;
};

export const DIALOG_CLOSED_STATE = {
  type: null,
  context: null,
};
