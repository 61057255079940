import React from 'react';
import { DidomiIconButton, DidomiTooltip } from '@didomi/ui-atoms-react';

export const RolesTableControlsCell = ({ onDeleteClicked, onDuplicateClicked, onEditClicked }) => (
  <div className="flex gap-xxs">
    <DidomiTooltip content="Edit role" placement="top">
      <DidomiIconButton
        aria-label="Edit role"
        type="button"
        variant="rounded"
        icon="edit"
        data-cy="edit-row"
        onClick={() => onEditClicked()}
      />
    </DidomiTooltip>
    <DidomiTooltip content="Duplicate role" placement="top">
      <DidomiIconButton
        aria-label="Duplicate role"
        type="button"
        variant="rounded"
        icon="copy"
        data-cy="duplicate-row"
        onClick={() => onDuplicateClicked()}
      />
    </DidomiTooltip>
    <DidomiTooltip content="Delete role" placement="top">
      <DidomiIconButton
        aria-label="Delete role"
        type="button"
        variant="rounded"
        icon="delete"
        data-cy="delete-row"
        onClick={() => onDeleteClicked()}
      />
    </DidomiTooltip>
  </div>
);
