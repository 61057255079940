import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { Role, RoleFormData } from '@types';
import { useAxiosWithLogOut } from './useAxiosWithInterceptors.hook';

interface EditRolesParams {
  roleId: string;
  roleData: RoleFormData;
}

export const useEditRole = (
  options?: UseMutationOptions<
    AxiosResponse<Role>,
    AxiosError<{ message: string }>,
    EditRolesParams
  >,
) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();
  const queryClient = useQueryClient();

  const updateRolesInCache = (updatedRole: Role) => {
    const cachedRoles = queryClient.getQueryData<Role[]>(['roles', organizationId]);

    // Update cache only if roles are already fetched
    if (cachedRoles) {
      queryClient.setQueryData<Role[]>(['roles', organizationId], prevRoles =>
        prevRoles.map(role => (role.id === updatedRole.id ? updatedRole : role)),
      );
    }
  };

  return useMutation(
    async ({ roleId, roleData }: EditRolesParams) => {
      return axiosWithInterceptors.patch<Role>(
        `${CONFIG.environmentConfig.apiBaseUrl}roles/${roleId}?organization_id=${organizationId}`,
        roleData,
      );
    },
    {
      ...options,
      onSuccess: (response, rolesData, context) => {
        updateRolesInCache(response.data);
        options.onSuccess?.(response, rolesData, context);
      },
    },
  );
};
