import React from 'react';
import { getCounterText } from '@didomi/helpers';
import { DidomiButton, DidomiFiltersBar } from '@didomi/ui-atoms-react';

type RolesTableToolbarProps = {
  numberOfResults: number;
  isCreateVisible?: boolean;
  onSearchChange?: (value: string) => void;
  onCreateClicked?: () => void;
};

export const RolesTableToolbar = ({
  numberOfResults,
  isCreateVisible = true,
  onSearchChange,
  onCreateClicked,
}: RolesTableToolbarProps) => {
  const resultsText = getCounterText(numberOfResults, 'role');

  const handleSearchChange = (e: CustomEvent<string>) => onSearchChange(e.detail);

  return (
    <section className="flex items-center gap-xs mb-xs">
      <div
        className="text-body-small text-primary-blue-6 font-semibold mr-auto"
        data-cy="results-counter"
      >
        {resultsText}
      </div>

      <DidomiFiltersBar
        data-cy="filters-bar"
        searchValue=""
        showSearch
        collapsibleFilters={false}
        onSearchTextChange={handleSearchChange}
      />

      {isCreateVisible && (
        <DidomiButton iconRight="new-create" onClick={onCreateClicked}>
          Create a role
        </DidomiButton>
      )}
    </section>
  );
};
