import { ExtendedRole } from '@types';

export const INITIAL_TABLE_STATE = {
  defaultSorting: { field: 'name', dir: 'asc' },
  defaultLimit: 10,
} as const;

export const SEARCH_FIELDS = [{ field: 'name' }, { field: 'description' }];

export type DialogState = {
  type: 'delete' | 'delete-forbidden' | 'duplicate' | null;
  context: ExtendedRole | null;
};

export const DIALOG_CLOSED_STATE = {
  type: null,
  context: null,
};
