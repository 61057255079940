import { addAxiosResponseInterceptors } from '@didomi/helpers';
import { useAuthMethods, useAuthToken } from '@didomi/utility-react';
import axios, { AxiosInstance } from 'axios';

export const axiosWithLogoutInstance = axios.create();
let axiosWithLogoutInterceptors: AxiosInstance;

export const useAxiosWithLogOut = () => {
  const token = useAuthToken();
  const { logout } = useAuthMethods();

  // Alter defaults after instance has been created
  axiosWithLogoutInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  axiosWithLogoutInstance.defaults.headers.common['X-Didomi-Client-Name'] = 'Users & Roles SPA';

  if (axiosWithLogoutInterceptors) {
    return axiosWithLogoutInterceptors;
  }

  axiosWithLogoutInterceptors = addAxiosResponseInterceptors(axiosWithLogoutInstance, {
    onExpiredToken: logout,
    onForbidden: logout,
  });
  return axiosWithLogoutInterceptors;
};
