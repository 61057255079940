import React from 'react';
import {
  DidomiButton,
  DidomiIllustration,
  DidomiModal,
  DidomiModalHeader,
  DidomiModalActions,
  DidomiButtonGroup,
} from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';
import { ExtendedRole } from '@types';

type ConfirmRoleDeleteModalProps = {
  isOpen: boolean;
  role: ExtendedRole | null;
  onCancel?: () => void;
  onConfirm?: () => void;
};

export const ConfirmRoleDeleteModal = ({
  isOpen,
  role,
  onCancel,
  onConfirm,
}: ConfirmRoleDeleteModalProps) => {
  return (
    <DidomiModal isOpen={isOpen} permanent variant="slim">
      <DidomiIllustration className={tx('mb-m')} name="delete-user" />
      <DidomiModalHeader modalSubTitle="This role will be deleted:" modalTitle={role?.name} />

      <DidomiModalActions>
        <DidomiButtonGroup>
          <DidomiButton variant="secondary" onClick={onCancel}>
            Cancel
          </DidomiButton>
          <DidomiButton data-cy="confirm-btn" onClick={onConfirm}>
            Delete role
          </DidomiButton>
        </DidomiButtonGroup>
      </DidomiModalActions>
    </DidomiModal>
  );
};
