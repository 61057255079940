import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { SsoConnection } from '@types';
import { useAxiosWithLogOut } from './useAxiosWithInterceptors.hook';

/**
 * Fetch SSO connections for the active organization
 * */
export const useSsoConnections = (options?: UseQueryOptions<SsoConnection[]>) => {
  const { organizationId } = useActiveOrganization();
  const axiosWithInterceptors = useAxiosWithLogOut();

  const fetchSsoConnections = async (): Promise<SsoConnection[]> => {
    const response = await axiosWithInterceptors.get<SsoConnection[]>(
      `${CONFIG.environmentConfig.apiBaseUrl}sso-connections?organization_id=${organizationId}`,
    );

    return response.data;
  };

  return useQuery<SsoConnection[], AxiosError>(
    ['sso-connections', organizationId],
    fetchSsoConnections,
    options,
  );
};
