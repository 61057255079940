import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import React from 'react';
import { useActiveOrganization } from '@didomi/utility-react';
import { ErrorState } from '@components';
import { HomeLayout } from '@layouts';
import { CreateRole, EditRole, Roles, Users } from '@pages';
import { ROUTES } from '@utils';

const getRouter = (basename: string) =>
  createBrowserRouter(
    [
      {
        path: '/',
        errorElement: <ErrorState />,
        children: [
          {
            path: '',
            element: <HomeLayout />,
            errorElement: <ErrorState />,
            children: [
              {
                path: ROUTES.USERS,
                element: <Users />,
              },
              {
                path: ROUTES.ROLES,
                element: <Roles />,
              },
            ],
          },
          {
            path: ROUTES.CREATE_ROLE,
            element: <CreateRole />,
          },
          {
            path: ROUTES.EDIT_ROLE,
            element: <EditRole />,
          },
        ],
      },
    ],
    { basename },
  );

export const OrganizationRouterProvider = () => {
  const { organizationId } = useActiveOrganization();
  const basename = `/${organizationId}/organization`;

  return <RouterProvider router={getRouter(basename)} />;
};
