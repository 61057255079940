import { useEffect } from 'react';

interface UseCatchLastPageNoItemsParams {
  page: number;
  data: unknown[];
  effect: (newPage: number) => void;
}

/*
 * If we are on non-first page and there is no data to display - change page to the previous one
 * Use case: We are on the last page and one item is displayed - we are deleting this item => we need to switch to the previous page
 */
export const useCatchLastPageNoItems = ({ page, data, effect }: UseCatchLastPageNoItemsParams) => {
  useEffect(() => {
    if (page > 1 && data.length === 0) {
      effect(page - 1);
    }
  }, [page, data, effect]);
};
