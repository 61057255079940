import * as Yup from 'yup';
import { PATTERNS } from '@utils';

export const DEFAULT_ROLE_DATA = {
  name: 'My Role name',
  description: '',
  access_policy_ids: [],
};

export const roleSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .matches(PATTERNS.roleName, 'Please enter a valid name.')
    .required('Please enter a name.'),
  description: Yup.string()
    .trim()
    .matches(PATTERNS.roleDescription, 'Please enter a valid description.'),
  access_policy_ids: Yup.object(),
});
