import React, { useMemo } from 'react';
import {
  DidomiBackButtonLink,
  DidomiButton,
  DidomiEditableTextBox,
  DidomiHeaderInput,
  DidomiSecondaryHeader,
} from '@didomi/ui-atoms-react';
import { Formik, Form } from 'formik';
import { AccessModuleBlock, RoleFormData, RoleFormValues } from '@types';
import { roleDataToRoleFormValues, roleFormValuesToRoleData } from '@utils';
import { AccessSelectCard } from 'components/AccessSelectCard/AccessSelectCard';
import { DEFAULT_ROLE_DATA, roleSchema } from './RoleFormHelpers';

interface RoleFormProps {
  roleData?: RoleFormData;
  isLoading?: boolean;
  isSaving?: boolean;
  accessModulesBlocks: AccessModuleBlock[];
  onBackClicked: () => void;
  onSubmit: (roleData: RoleFormData) => void;
}

export const RoleForm = ({
  roleData = DEFAULT_ROLE_DATA,
  accessModulesBlocks,
  isSaving,
  onBackClicked,
  onSubmit,
}: RoleFormProps) => {
  const formInitialValues = useMemo(() => roleDataToRoleFormValues(roleData), [roleData]);

  const handleSave = (values: RoleFormValues) => {
    onSubmit(roleFormValuesToRoleData(values));
  };

  return (
    <Formik
      initialValues={formInitialValues}
      enableReinitialize
      validationSchema={roleSchema}
      onSubmit={handleSave}
    >
      {({ values, setFieldValue, errors }) => (
        <Form noValidate>
          <DidomiSecondaryHeader className="block mb-m">
            {/* Name Field */}
            <div slot="header-title" className="!block" data-skeleton>
              <DidomiHeaderInput
                tooltipContent="Edit name"
                icon="edit"
                data-cy="role-name-input"
                value={values.name}
                onValueChange={e => setFieldValue('name', e.detail)}
              />
              {errors.name && <div className="text-xs text-secondary-red-4">{errors.name}</div>}
            </div>

            <div slot="back-button">
              <DidomiBackButtonLink
                class="cursor-pointer"
                text="Back to Roles"
                onClick={onBackClicked}
              />
            </div>
            <div slot="actions">
              <DidomiButton type="submit" variant="main" iconRight="save" disabled={isSaving}>
                Save & Close
              </DidomiButton>
            </div>
          </DidomiSecondaryHeader>

          {/* Description Field */}
          <div className="!block mb-s" data-skeleton>
            <DidomiEditableTextBox
              label="Add a description"
              labelIcon="edit"
              placeholder="Add a description"
              data-cy="role-description-input"
              value={values.description}
              onValueChange={e => setFieldValue('description', e.detail)}
            ></DidomiEditableTextBox>
            {errors.description && (
              <div className="text-xs text-secondary-red-4">{errors.description}</div>
            )}
          </div>

          <p className="font-bold text-2xl mb-6">Choose the access type you allow for this role</p>

          <div className="!grid gap-s grid-cols-fill-300" data-skeleton>
            {accessModulesBlocks.map(module => (
              <AccessSelectCard
                key={module.id}
                titleText={module.displayedName}
                tooltipText={module.tooltipText}
                illustration={module.illustration}
                accessOptions={module.accessOptions}
                value={values.access_policy_ids[module.id]}
                onValueChange={value => setFieldValue(`access_policy_ids.${module.id}`, value)}
              />
            ))}
          </div>
        </Form>
      )}
    </Formik>
  );
};
