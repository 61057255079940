import React from 'react';
import {
  DidomiCardContainer,
  DidomiIcon,
  DidomiIllustration,
  DidomiSelect,
  DidomiSelectOption,
  DidomiSelectOptions,
  DidomiTooltip,
} from '@didomi/ui-atoms-react';
import { SelectOptionModel } from '@types';

interface AccessSelectCardProps {
  titleText: string;
  tooltipText: string;
  illustration: string;
  accessOptions: SelectOptionModel[];
  value: string;
  onValueChange: (value: string) => void;
}

export const AccessSelectCard = ({
  titleText,
  tooltipText,
  illustration,
  accessOptions,
  value,
  onValueChange,
}: AccessSelectCardProps) => {
  return (
    <DidomiCardContainer aria-label={'Access to ' + titleText}>
      <div className="flex justify-between items-center gap-4 mb-8">
        <div className="flex items-center gap-1 font-semibold text-primary-blue-6">
          {titleText}

          {tooltipText && (
            <DidomiTooltip className="leading-none" content={tooltipText}>
              <DidomiIcon name="helper-text" />
            </DidomiTooltip>
          )}
        </div>

        <DidomiIllustration className="flex-shrink-0" name={illustration as any} />
      </div>

      <DidomiSelect
        placeholder="No Access"
        value={value}
        hideErrorMessage
        onValueChange={e => onValueChange(e.detail as string)}
      >
        <DidomiSelectOptions>
          {accessOptions.map(option => (
            <DidomiSelectOption key={option.value} label={option.label} value={option.value} />
          ))}
          <DidomiSelectOption label="No Access" value="" />
        </DidomiSelectOptions>
      </DidomiSelect>
    </DidomiCardContainer>
  );
};
