import { UserFormData } from '@types';

// As Select works only with strings we need to use custom value
export const NO_ROLE_FIELD_VALUE = 'no-role';

/*
 * Check whether role_id is 'no-role' string
 * And, if yes - transform it into the null value before sending it to the API
 */
export const normalizeUserFormValuesForSubmit = (formValue: UserFormData) => {
  const role_id = formValue.role_id === NO_ROLE_FIELD_VALUE ? null : formValue.role_id;
  return { ...formValue, role_id };
};

/*
 * Transform null role_id into 'no-role' in order to display correct role option in the form
 */
export const normalizeUserFormValuesForForm = (formValue: UserFormData) => {
  const role_id = formValue.role_id === null ? NO_ROLE_FIELD_VALUE : formValue.role_id;
  return { ...formValue, role_id };
};
