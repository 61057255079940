import React from 'react';
import { DidomiModal, DidomiModalContent, DidomiModalHeader } from '@didomi/ui-atoms-react';
import { UserForm } from '@components';
import { Member, Role, UserFormData } from '@types';

interface EditUserModalProps {
  isOpen: boolean;
  member?: Member;
  roles: Role[];
  onCancel: () => void;
  onSave: (data: UserFormData) => void;
}

export const EditUserModal = ({ isOpen, member, roles, onCancel, onSave }: EditUserModalProps) => {
  /* istanbul ignore next */
  const handleOnChange = e => {
    if (isOpen && !e.detail) {
      onCancel();
    }
  };

  const initialUserFormData = member
    ? { email: member.user.email, name: member.user.name, role_id: member.role_id }
    : null;

  return (
    <DidomiModal isOpen={isOpen} onOpenChange={handleOnChange}>
      <DidomiModalHeader variant="warning" modalTitle="Edit user" />

      <DidomiModalContent>
        <UserForm
          initialFormState={initialUserFormData}
          roles={roles}
          onSave={onSave}
          onCancel={onCancel}
        />
      </DidomiModalContent>
    </DidomiModal>
  );
};
