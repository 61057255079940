import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { MemberCore, UserFormData } from '@types';
import { useAxiosWithLogOut } from './useAxiosWithInterceptors.hook';

export const useInviteMember = (
  options?: UseMutationOptions<
    AxiosResponse<MemberCore>,
    AxiosError<{ message: string }>,
    UserFormData
  >,
) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId, organization } = useActiveOrganization();
  const queryClient = useQueryClient();

  return useMutation(
    async (memberData: UserFormData) => {
      const payload = {
        ...memberData,
        organization_id: organizationId,
        self_register: organization.self_register,
      };

      return axiosWithInterceptors.post<MemberCore>(
        `${CONFIG.environmentConfig.apiBaseUrl}members`,
        payload,
      );
    },
    {
      ...options,
      onSuccess: (response, memberData, context) => {
        // TODO: remove users list re-fetching when API returns full member object in response - PLAT-1689
        queryClient.invalidateQueries(['members', organizationId]);
        options.onSuccess?.(response, memberData, context);
      },
    },
  );
};
