import React from 'react';
import { SortConfig } from '@didomi/helpers';
import {
  DidomiTable,
  DidomiTableHeading,
  DidomiTableHeaderRow,
  DidomiTableTh,
  DidomiTableBody,
  DidomiTableRow,
  DidomiTableTd,
} from '@didomi/ui-atoms-react';
import { ExtendedRole } from '@types';
import { RolesTableControlsCell } from './RolesTableHelpers';
import { TextWithHighlighterAndTooltip } from '../TextWithHighlighterAndTooltip/TextWithHighlighterAndTooltip';

interface RolesTableProps {
  className?: string;
  roles: ExtendedRole[];
  sortConfig: SortConfig;
  search: string;
  hasEditAccess: boolean;
  onSortChange: (sortConfig: CustomEvent<{ sortId: string; direction: 'asc' | 'desc' }>) => void;
  onEditClicked: (role: ExtendedRole) => void;
  onDuplicateClicked: (role: ExtendedRole) => void;
  onDeleteClicked: (role: ExtendedRole) => void;
}

export const RolesTable = ({
  className,
  roles,
  sortConfig,
  search,
  hasEditAccess,
  onSortChange,
  onEditClicked,
  onDuplicateClicked,
  onDeleteClicked,
}: RolesTableProps) => (
  <DidomiTable
    className={className}
    sortBy={sortConfig.field}
    sortDirection={sortConfig.dir}
    onSortChange={onSortChange}
    sortable
  >
    <DidomiTableHeading>
      <DidomiTableHeaderRow>
        <DidomiTableTh data-cy="role-name-th" sortId="name">
          NAME
        </DidomiTableTh>
        <DidomiTableTh sortId="members_count">USERS</DidomiTableTh>
        <DidomiTableTh sortId="description">DESCRIPTION</DidomiTableTh>

        {/* Actions Column */}
        {hasEditAccess && <DidomiTableTh></DidomiTableTh>}
      </DidomiTableHeaderRow>
    </DidomiTableHeading>

    <DidomiTableBody>
      {/** There is a bug in the ui-library that breaks re-rendering the rows dynamically - wrapping the content in a div fixes the issue */}
      <div>
        {roles.map(role => (
          <DidomiTableRow key={role.id}>
            <DidomiTableTd data-cy="role-name-td">
              <TextWithHighlighterAndTooltip searchText={search} text={role.name} />
            </DidomiTableTd>
            <DidomiTableTd>
              <div>{role.members_count}</div>
            </DidomiTableTd>
            <DidomiTableTd>
              <TextWithHighlighterAndTooltip searchText={search} text={role.description} />
            </DidomiTableTd>

            {/* Actions Column */}
            {hasEditAccess && (
              <DidomiTableTd cellAlign="right">
                {/* Show controls only for roles that belong to organization */}
                {!!role.organization_id && (
                  <RolesTableControlsCell
                    onEditClicked={() => onEditClicked(role)}
                    onDuplicateClicked={() => onDuplicateClicked(role)}
                    onDeleteClicked={() => onDeleteClicked(role)}
                  />
                )}
              </DidomiTableTd>
            )}
          </DidomiTableRow>
        ))}
      </div>
    </DidomiTableBody>
  </DidomiTable>
);
