import { ExtendedMember, Member, Role } from '@types';

export const joinMembersWithRoles = (
  members: Member[] = [],
  roles: Role[] = [],
): ExtendedMember[] => {
  const getRoleName = (roleId: string) => roles.find(role => role.id === roleId)?.name;

  return members.map(member => ({ ...member, role_name: getRoleName(member.role_id) }));
};
