import React, { useMemo } from 'react';
import { DidomiButton, DidomiTextInputField } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';
import { Form, Formik } from 'formik';
import { getDuplicateRoleSchema } from './DuplicateRoleFormHelpers';

interface DuplicateRoleFormProps {
  initialFormState: { name: string };
  alreadyTakenNames: string[];
  onSave: (values: { name: string }) => void;
  onCancel: () => void;
}

export const DuplicateRoleForm = ({
  initialFormState,
  alreadyTakenNames,
  onSave,
  onCancel,
}: DuplicateRoleFormProps) => {
  const validationSchema = useMemo(
    () => getDuplicateRoleSchema(alreadyTakenNames),
    [alreadyTakenNames],
  );

  return (
    <Formik
      initialValues={initialFormState}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={onSave}
    >
      <Form className={tx('w-[400px]')} noValidate>
        <DidomiTextInputField name="name" data-cy="role-name-input" required />

        <div className={tx('flex gap-xs justify-center mt-xxxs')}>
          <DidomiButton variant="secondary" onClick={onCancel}>
            Cancel
          </DidomiButton>
          <DidomiButton type="submit" data-cy="confirm-btn">
            Create role
          </DidomiButton>
        </div>
      </Form>
    </Formik>
  );
};
