import React, { useMemo, useState } from 'react';
import { getCounterText } from '@didomi/helpers';
import { DidomiFiltersBarCustomEvent } from '@didomi/ui-atoms';
import { DidomiButton, DidomiFiltersBar } from '@didomi/ui-atoms-react';
import { Role } from '@types';
import { getRolesOptions } from '@utils';

type UsersTableToolbarProps = {
  numberOfResults: number;
  isCreateVisible?: boolean;
  availableRoles: Role[];
  onSearchChange?: (value: string) => void;
  onFilterChange?: (roleId: string) => void;
  onCreateClicked?: () => void;
};

export const UsersTableToolbar = ({
  numberOfResults,
  isCreateVisible = true,
  availableRoles = [],
  onSearchChange,
  onFilterChange,
  onCreateClicked,
}: UsersTableToolbarProps) => {
  const [roleFilter, setRoleFilter] = useState<string>();
  const resultsText = getCounterText(numberOfResults, 'user');

  const handleSearchChange = (e: CustomEvent<string>) => onSearchChange(e.detail);
  const handleFilterChange = (
    e: DidomiFiltersBarCustomEvent<{ key: string; newValue: string }>,
  ) => {
    setRoleFilter(e.detail.newValue);
    onFilterChange(e.detail.newValue);
  };

  const filterOptions = useMemo(() => getRolesOptions(availableRoles), [availableRoles]);

  const filters = useMemo(
    () => ({
      filterByRole: {
        placeholder: 'All roles',
        value: roleFilter,
        options: filterOptions,
      },
    }),
    [roleFilter, filterOptions],
  );

  return (
    <section className="flex items-center gap-xs mb-xs">
      <div
        className="text-body-small text-primary-blue-6 font-semibold mr-auto"
        data-cy="results-counter"
      >
        {resultsText}
      </div>

      <DidomiFiltersBar
        data-cy="filters-bar"
        searchValue=""
        showSearch
        collapsibleFilters={false}
        filters={filters}
        onSearchTextChange={handleSearchChange}
        onFilterValueChange={handleFilterChange}
      />

      {isCreateVisible && (
        <DidomiButton
          iconRight="new-create"
          data-cy="create-modal-trigger"
          onClick={onCreateClicked}
        >
          Invite a user
        </DidomiButton>
      )}
    </section>
  );
};
