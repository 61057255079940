import { useRouteError } from 'react-router-dom';
import React, { useEffect } from 'react';
import { DidomiErrorState } from '@didomi/ui-atoms-react';
import { reportSpaError } from '@utils';

export const ErrorState = () => {
  const error: any = useRouteError();

  useEffect(() => {
    if (error) {
      reportSpaError(error);
    }
  }, [error]);

  return (
    <div className="h-full p-l">
      <DidomiErrorState illustrationName="content-loading-error" className="h-full">
        <div slot="title">There seems to be an error</div>
        <div slot="description">
          Please try refreshing the page or contact{' '}
          <a className="underline" href="mailto:support@didomi.io?subject=Users&Roles Error">
            support@didomi.io
          </a>{' '}
          for further assistance.
        </div>
      </DidomiErrorState>
    </div>
  );
};
