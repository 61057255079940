import { useNavigate } from 'react-router-dom';
import React, { useMemo, useState } from 'react';
import { filterSortPaginate } from '@didomi/helpers';
import { useTableState } from '@didomi/helpers-react';
import { DidomiEmptyState, DidomiPaginator } from '@didomi/ui-atoms-react';
import { useHasAccessPolicies } from '@didomi/utility-react';
import { Loader, RolesTable, RolesTableToolbar } from '@components';
import { useCatchLastPageNoItems, useRolesApi } from '@hooks';
import {
  ConfirmRoleDeleteModal,
  DuplicateRoleModal,
  LoadingModal,
  RoleDeleteForbiddenModal,
} from '@modals';
import { ACCESS_POLICIES_CONFIG, ROUTES, joinRolesWithMembers } from '@utils';
import {
  DIALOG_CLOSED_STATE,
  DialogState,
  INITIAL_TABLE_STATE,
  SEARCH_FIELDS,
} from './RolesHelpers';

export const Roles = () => {
  const [dialogState, setDialogState] = useState<DialogState>(DIALOG_CLOSED_STATE);
  const { members, roles, isLoading, isSaving, deleteRole, createRole } = useRolesApi({
    loadMembers: true,
    loadRoles: true,
  });
  const { hasAccess: hasEditAccess } = useHasAccessPolicies(ACCESS_POLICIES_CONFIG.EDITOR);
  const navigate = useNavigate();

  const rolesData = useMemo(() => joinRolesWithMembers(roles, members), [roles, members]);
  const rolesNames = useMemo(() => roles?.map(role => role.name), [roles]);
  const tableState = useTableState(INITIAL_TABLE_STATE);

  const { data: rolesToDisplay, total: paginationTotal } = useMemo(
    () =>
      filterSortPaginate(rolesData, {
        search: tableState.search,
        sortConfig: tableState.sortConfig,
        limit: tableState.limit,
        page: tableState.page,
        searchFields: SEARCH_FIELDS,
      }),
    [tableState, rolesData],
  );
  useCatchLastPageNoItems({
    page: tableState.page,
    data: rolesToDisplay,
    effect: page => tableState.changePage(new CustomEvent('', { detail: { page } })),
  });

  const isEmpty = rolesToDisplay.length === 0;

  return (
    <section className="w-full">
      <RolesTableToolbar
        numberOfResults={paginationTotal}
        isCreateVisible={hasEditAccess}
        onSearchChange={tableState.changeSearch}
        onCreateClicked={() => navigate(ROUTES.CREATE_ROLE)}
      />

      {!isEmpty && (
        <>
          <RolesTable
            className="mb-xs"
            roles={rolesToDisplay}
            sortConfig={tableState.sortConfig}
            search={tableState.search}
            hasEditAccess={hasEditAccess}
            onDeleteClicked={role =>
              setDialogState({
                type: role.members_count ? 'delete-forbidden' : 'delete',
                context: role,
              })
            }
            onEditClicked={role => navigate(ROUTES.EDIT_ROLE.replace(':roleId', role.id))}
            onDuplicateClicked={role => setDialogState({ type: 'duplicate', context: role })}
            onSortChange={tableState.changeSorting}
          />

          <div className="flex justify-end">
            <DidomiPaginator
              data-testid="roles-paginator"
              className="self-end"
              page={tableState.page}
              itemCount={paginationTotal}
              size={tableState.limit as any}
              onPageSizeChange={tableState.changeLimit}
              onPageChange={tableState.changePage}
            />
          </div>
        </>
      )}

      {/* Empty State */}
      {isEmpty && !isLoading && (
        <DidomiEmptyState
          illustration-name="traces-no-result-found"
          actionName={hasEditAccess ? 'Create a role' : ''}
          actionIconRight={hasEditAccess ? 'new-create' : ''}
          onActionClick={() => navigate(ROUTES.CREATE_ROLE)}
        >
          <div slot="title">It&apos;s empty here!</div>
          No results
        </DidomiEmptyState>
      )}

      {/* Loading State */}
      {isLoading && <Loader>Loading roles</Loader>}

      {/* Modals */}
      <LoadingModal isOpen={isSaving} title="Saving changes..." />
      <ConfirmRoleDeleteModal
        isOpen={dialogState.type == 'delete'}
        role={dialogState.context}
        onConfirm={() => {
          deleteRole(dialogState.context.id);
          setDialogState(DIALOG_CLOSED_STATE);
        }}
        onCancel={() => setDialogState(DIALOG_CLOSED_STATE)}
      />
      <RoleDeleteForbiddenModal
        isOpen={dialogState.type === 'delete-forbidden'}
        role={dialogState.context}
        onCancel={() => setDialogState(DIALOG_CLOSED_STATE)}
      />
      <DuplicateRoleModal
        isOpen={dialogState.type === 'duplicate'}
        baseRole={dialogState.context}
        alreadyTakenNames={rolesNames}
        onSave={roleData => {
          createRole(roleData);
          setDialogState(DIALOG_CLOSED_STATE);
        }}
        onCancel={() => setDialogState(DIALOG_CLOSED_STATE)}
      />
    </section>
  );
};
