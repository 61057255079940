import React from 'react';
import { SortConfig } from '@didomi/helpers';
import {
  DidomiTable,
  DidomiTableHeading,
  DidomiTableHeaderRow,
  DidomiTableTh,
  DidomiTableBody,
  DidomiTableRow,
  DidomiTableTd,
  DidomiChip,
} from '@didomi/ui-atoms-react';
import { ExtendedMember, Member } from '@types';
import { CHIP_CONFIG_PER_USER_STATUS } from '@utils';
import { AuthMethodCellContent, UsersTableControlsCell } from './UsersTableHelpers';
import { TextWithHighlighterAndTooltip } from '../TextWithHighlighterAndTooltip/TextWithHighlighterAndTooltip';

interface UsersTableProps {
  className?: string;
  members: ExtendedMember[];
  sortConfig: SortConfig;
  search: string;
  hasEditAccess: boolean;
  onSortChange: (sortConfig: CustomEvent<{ sortId: string; direction: 'asc' | 'desc' }>) => void;
  onEditClicked: (member: Member) => void;
  onDeleteClicked: (member: Member) => void;
}

export const UsersTable = ({
  className,
  members,
  sortConfig,
  search,
  hasEditAccess,
  onSortChange,
  onEditClicked,
  onDeleteClicked,
}: UsersTableProps) => (
  <DidomiTable
    className={className}
    sortBy={sortConfig.field}
    sortDirection={sortConfig.dir}
    onSortChange={onSortChange}
    sortable
  >
    <DidomiTableHeading>
      <DidomiTableHeaderRow>
        <DidomiTableTh data-cy="user-email-th" sortId="user.email">
          EMAIL ADDRESS
        </DidomiTableTh>
        <DidomiTableTh sortId="user.name">NAME</DidomiTableTh>
        <DidomiTableTh sortId="role_name">ROLE</DidomiTableTh>
        <DidomiTableTh sortId="user.status">STATUS</DidomiTableTh>
        <DidomiTableTh sortId="user.id">ID</DidomiTableTh>
        <DidomiTableTh sortId="user.authenticationMethod">AUTHENTICATION METHOD</DidomiTableTh>

        {/* Actions Column */}
        {hasEditAccess && <DidomiTableTh></DidomiTableTh>}
      </DidomiTableHeaderRow>
    </DidomiTableHeading>

    <DidomiTableBody>
      {/** There is a bug in the ui-library that breaks re-rendering the rows dynamically - wrapping the content in a div fixes the issue */}
      <div>
        {members.map(member => (
          <DidomiTableRow key={member.id}>
            <DidomiTableTd>
              <TextWithHighlighterAndTooltip searchText={search} text={member.user.email} />
            </DidomiTableTd>
            <DidomiTableTd data-cy="user-name-td">
              <TextWithHighlighterAndTooltip searchText={search} text={member.user.name} />
            </DidomiTableTd>
            <DidomiTableTd data-cy="user-role-td">
              <TextWithHighlighterAndTooltip searchText={search} text={member.role_name} />
            </DidomiTableTd>
            <DidomiTableTd>
              <DidomiChip
                basicType={CHIP_CONFIG_PER_USER_STATUS[member.user.status]?.type}
                leftIcon={CHIP_CONFIG_PER_USER_STATUS[member.user.status]?.icon}
                label={CHIP_CONFIG_PER_USER_STATUS[member.user.status]?.label}
              />
            </DidomiTableTd>
            <DidomiTableTd>
              <TextWithHighlighterAndTooltip searchText={search} text={member.id} />
            </DidomiTableTd>
            <DidomiTableTd>
              <AuthMethodCellContent
                authMethod={member.user.authentication_method}
                samlActive={member.user.saml_active}
              />
            </DidomiTableTd>

            {hasEditAccess && (
              <DidomiTableTd cellAlign="right">
                <UsersTableControlsCell
                  onEditClicked={() => onEditClicked(member)}
                  onDeleteClicked={() => onDeleteClicked(member)}
                />
              </DidomiTableTd>
            )}
          </DidomiTableRow>
        ))}
      </div>
    </DidomiTableBody>
  </DidomiTable>
);
