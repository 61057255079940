import React from 'react';
import { DidomiModal, DidomiModalContent, DidomiModalHeader } from '@didomi/ui-atoms-react';
import { UserForm } from '@components';
import { Role, UserFormData } from '@types';

interface InviteUserModalProps {
  isOpen: boolean;
  alreadyTakenEmails?: string[];
  roles: Role[];
  onCancel: () => void;
  onSave: (data: UserFormData) => void;
}

export const InviteUserModal = ({
  isOpen,
  alreadyTakenEmails,
  roles,
  onCancel,
  onSave,
}: InviteUserModalProps) => {
  /* istanbul ignore next */
  const handleOnChange = e => {
    if (isOpen && !e.detail) {
      onCancel();
    }
  };

  return (
    <DidomiModal isOpen={isOpen} onOpenChange={handleOnChange}>
      <DidomiModalHeader variant="warning" modalTitle="Invite a user" />

      <DidomiModalContent>
        {/* Re-initialize form when modal is opened */}
        {isOpen && (
          <UserForm
            roles={roles}
            alreadyTakenEmails={alreadyTakenEmails}
            onSave={onSave}
            onCancel={onCancel}
          />
        )}
      </DidomiModalContent>
    </DidomiModal>
  );
};
