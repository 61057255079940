import * as Yup from 'yup';
import { UserFormData } from '@types';

export const getUserSchema = (takenEmails = []) =>
  Yup.object().shape({
    email: Yup.string()
      .email('Please enter a valid email address.')
      .trim()
      .notOneOf(takenEmails, 'User with this email already exists in this organization.')
      .required('Please enter an email address.'),
    name: Yup.string().trim().required('Please enter a name.'),
    role_id: Yup.string().required('Please select a role.'),
  });

export const DEFAULT_INITIAL_FORM_STATE: UserFormData = {
  email: '',
  name: '',
  role_id: '',
};
