import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { Role } from '@types';
import { useAxiosWithLogOut } from './useAxiosWithInterceptors.hook';

export const useDeleteRole = (
  options?: UseMutationOptions<AxiosResponse<Role>, AxiosError<{ message: string }>, string>,
) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();
  const queryClient = useQueryClient();

  const deleteRoleFromCache = (roleId: string) => {
    queryClient.setQueryData<Role[]>(['roles', organizationId], prevRoles =>
      prevRoles.filter(role => role.id !== roleId),
    );
  };

  return useMutation(
    async (roleId: string) => {
      return axiosWithInterceptors.delete(
        `${CONFIG.environmentConfig.apiBaseUrl}roles/${roleId}?organization_id=${organizationId}`,
      );
    },
    {
      ...options,
      onSuccess: (response: AxiosResponse<Role>, deletedRoleId, context) => {
        deleteRoleFromCache(deletedRoleId);
        options.onSuccess?.(response, deletedRoleId, context);
      },
    },
  );
};
