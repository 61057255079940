import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { Member, MemberCore } from '@types';
import { useAxiosWithLogOut } from './useAxiosWithInterceptors.hook';

export const useDeleteMember = (
  options?: UseMutationOptions<AxiosResponse<MemberCore>, AxiosError<{ message: string }>, string>,
) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();
  const queryClient = useQueryClient();

  const deleteMemberFromCache = (memberId: string) => {
    queryClient.setQueryData<Member[]>(['members', organizationId], prevMembers =>
      prevMembers.filter(member => member.id !== memberId),
    );
  };

  return useMutation(
    async (memberId: string) => {
      return axiosWithInterceptors.delete(
        `${CONFIG.environmentConfig.apiBaseUrl}members/${memberId}`,
      );
    },
    {
      ...options,
      onSuccess: (response: AxiosResponse<MemberCore>, deletedMemberId, context) => {
        deleteMemberFromCache(deletedMemberId);
        options.onSuccess?.(response, deletedMemberId, context);
      },
    },
  );
};
