import { useNavigate, useParams } from 'react-router-dom';
import React, { useMemo } from 'react';
import { DidomiSkeleton } from '@didomi/ui-atoms-react';
import { RoleForm } from '@components';
import { useAccessModules, useRolesApi } from '@hooks';
import { LoadingModal } from '@modals';
import { RoleFormData } from '@types';
import { ROUTES, getRoleFormDataByRoleId } from '@utils';

export const EditRole = () => {
  const { roleId } = useParams();
  const navigate = useNavigate();
  const { editRole, roles, isLoading, isSaving } = useRolesApi({ loadRoles: true });
  const { accessModulesBlocks } = useAccessModules();

  const initialRoleFormData = useMemo(
    () => getRoleFormDataByRoleId(roleId, roles),
    [roles, roleId],
  );

  const handleEditRole = (roleData: RoleFormData) => {
    editRole({ roleId, roleData }, { onSuccess: () => navigate(ROUTES.ROLES) });
  };

  return (
    <section className="p-l pb-20 text-primary-blue-6">
      <DidomiSkeleton variant="layout" isLoading={isLoading}>
        <RoleForm
          isSaving={isSaving}
          roleData={initialRoleFormData}
          accessModulesBlocks={accessModulesBlocks}
          onBackClicked={() => navigate(ROUTES.ROLES)}
          onSubmit={handleEditRole}
        />
      </DidomiSkeleton>
      <LoadingModal isOpen={isSaving} title="Saving changes..." />
    </section>
  );
};
