import React from 'react';
import { DidomiModal, DidomiModalHeader, DidomiModalContent } from '@didomi/ui-atoms-react';
import { DuplicateRoleForm } from '@components';
import { ExtendedRole, RoleFormData } from '@types';

type DuplicateRoleModalProps = {
  isOpen: boolean;
  baseRole: ExtendedRole | null;
  alreadyTakenNames: string[];
  onCancel?: () => void;
  onSave?: (roleData: RoleFormData) => void;
};

export const DuplicateRoleModal = ({
  isOpen,
  baseRole,
  alreadyTakenNames,
  onCancel,
  onSave,
}: DuplicateRoleModalProps) => {
  const handleFormSubmit = (formValue: { name: string }) => {
    onSave({
      name: formValue.name,
      // Use description and access_policy_ids from the base role
      description: baseRole.description,
      access_policy_ids: baseRole.access_policy_ids,
    });
  };

  return (
    <DidomiModal isOpen={isOpen} permanent>
      <DidomiModalHeader
        modalSubTitle={`Duplicating role: ${baseRole?.name}`}
        modalTitle="Please choose a role name"
      />

      <DidomiModalContent>
        <DuplicateRoleForm
          initialFormState={{ name: `${baseRole?.name} copy` }}
          alreadyTakenNames={alreadyTakenNames}
          onSave={handleFormSubmit}
          onCancel={onCancel}
        />
      </DidomiModalContent>
    </DidomiModal>
  );
};
