import { useSnackbar } from '@didomi/utility-react';
import { useCreateRole } from './useCreateRole.hook';
import { useDeleteRole } from './useDeleteRole.hook';
import { useEditRole } from './useEditRole.hook';
import { useMembers } from './useMembers.hook';
import { useRoles } from './useRoles.hook';

interface UseRolesApiOptions {
  loadRoles?: boolean;
  loadMembers?: boolean;
}

export const useRolesApi = ({
  loadRoles = false,
  loadMembers = false,
}: UseRolesApiOptions = {}) => {
  const { data: members, isLoading: isMemberLoading } = useMembers({ enabled: loadMembers });
  const { data: roles, isLoading: isRolesLoading } = useRoles({ enabled: loadRoles });
  const { displaySnackbar } = useSnackbar();

  const handleApiError = err => {
    const message = err?.response?.data?.message || `An error occurred while saving the changes`;
    displaySnackbar(message, { variant: 'error', duration: 5000 });
  };

  // Mutations
  const { mutate: deleteRole, isLoading: isDeleting } = useDeleteRole({
    onSuccess: () => displaySnackbar(`Role has been deleted`),
    onError: handleApiError,
  });

  const { mutate: createRole, isLoading: isCreating } = useCreateRole({
    onSuccess: () => displaySnackbar('New role created', { icon: 'check' }),
    onError: handleApiError,
  });

  const { mutate: editRole, isLoading: isUpdating } = useEditRole({
    onSuccess: () => displaySnackbar('Role has been updated', { icon: 'check' }),
    onError: handleApiError,
  });

  const isLoading = isMemberLoading || isRolesLoading;
  const isSaving = isDeleting || isCreating || isUpdating;

  return {
    members,
    roles,
    isLoading,
    isSaving,
    deleteRole,
    createRole,
    editRole,
  };
};
