import { PaginatedResponse } from '@didomi/helpers';
import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { Role } from '@types';
import { useAxiosWithLogOut } from './useAxiosWithInterceptors.hook';

/**
 * Fetch all roles
 * */
export const useRoles = (options?: UseQueryOptions<Role[]>) => {
  const { organizationId } = useActiveOrganization();
  const axiosWithInterceptors = useAxiosWithLogOut();

  const fetchRoles = async (): Promise<Role[]> => {
    const [orgRolesResponse, globalRoles] = await Promise.all([
      axiosWithInterceptors.get<PaginatedResponse<Role>>(
        `${CONFIG.environmentConfig.apiBaseUrl}roles?organization_id=${organizationId}`,
      ),
      axiosWithInterceptors.get<PaginatedResponse<Role>>(
        `${CONFIG.environmentConfig.apiBaseUrl}roles?organization_id=null`,
      ),
    ]);

    return orgRolesResponse.data.data
      .concat(globalRoles.data.data)
      .sort((a, b) => a.name.localeCompare(b.name));
  };

  return useQuery<Role[], AxiosError>(['roles', organizationId], fetchRoles, options);
};
